<template>
  <div class="header-info">
     <div class="left">
       <div class="home">
         <span>
            <span class="home-index" @click="isGoBack">
              <i class="iconfont iconxinxiku"></i>
              文献库首页
            </span>
            / {{info}}
          </span>
       </div>
       <div class="header-formulas" v-if="formulas.length">
         <div class="formula-item"
          v-for="(item, index) in formulas"
          :key="item"
         >
         <!-- <span v-if="isAndLogic(index)">{{(disease && index === 1) ? '-' : 'and'}}</span> -->
         <span v-if="isAndLogic(index)">{{isInit(index) ? '-' : 'and'}}</span>
         {{item}}
         <!-- <span v-if="index === 0 && disease && formulas.length > 1 ">-</span> -->
         <span v-if="isSingle(index)">-</span>
         </div>
       </div>
     </div>
     <div class="right">
       <div class="clear" @click="isClear" v-if="!diff">
         <i class="iconfont iconshanchu"></i>
         <span>清空筛选</span>
       </div>
        <Button style="font-size: 16px" @click="isSubscribe" v-if="diff !== 'collect'" type="primary" ghost>订阅</Button>
     </div>
  </div>
</template>

<script>
import { Button } from 'view-design'
export default {
  name: 'HeaderInfo',

  props: {
    info: {
      type: String,
      default: '文献列表'
    },
    formulas: {
      type: Array,
      default: () => []
    },
    diff: {
      type: String,
      default: ''
    },
    disease: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    // 疾病领域的label长度
    init: {
      type: Number,
      default: 0
    }
  },

  components: {
    Button
  },

  computed: {
    isAndLogic() {
      return function (index) {
        if (this.disease) {
          return (index !== 0 && index !== 1)
        } else if (this.field) {
          return index !== 0 || (index !== 0 && this.diff)
        } else {
          return (index !== 0 && this.diff)
        }
      }
    },
    isInit() {
      return function (index) {
        return (this.init === 2)
          ? (index === 2)
          : (this.init === 1)
            ? index === 1
            : false
      }
    },
    isSingle() {
      return function (index) {
        return (this.init === 2) ? (index === 0) : false
      }
    }
  },

  methods: {
    isGoBack() {
      this.$emit('back')
    },
    isClear() {
      this.$emit('clear')
    },
    isSubscribe() {
      this.$emit('subscribe')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>