import { isChildrenEmpty } from '@/utils/tools'

export const listMixins = {
  computed: {
    isBioMarkerShow() {
      return !!this.filterOption.bio_marker.children.length
    },
    isSecondCardShow() {
      const { disease, clinical_application, population } = this.filterOption
      return isChildrenEmpty(disease, clinical_application, population)
    }
    // isThirdCardShow() {
    //   const { language, article_type } = this.filterOption
    //   return isChildrenEmpty(language, article_type, publish_year, impact_factor)
    // },
    // isFactorYear({ sliderOption }) {
    //   const { publish_year, impact_factor } = sliderOption
    //   return publish_year.length && impact_factor.length
    // }
  }
}