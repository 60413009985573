export const biomarker = {
  label: '生物标志物',
  value: 'bio_marker',
  unfold: true,
  children: []
}

export const relateDisease = {
  label: '相关疾病',
  value: 'disease',
  children: []
}

export const clinical = {
  label: '临床应用',
  value: 'clinical_application',
  children: []
}

export const crowd = {
  label: '研究人群',
  value: 'population',
  children: []
}

export const language = {
  label: '语言类型',
  value: 'language',
  children: []
}

export const literature = {
  label: '文献类型',
  value: 'article_type',
  children: []
}

export const publish = {
  label: '发表年份',
  value: 'publish_year',
  children: [],
  range: [],
  valueRange: []
}

export const impact = {
  label: '影响因子',
  value: 'impact_factor',
  children: [],
  range: [],
  valueRange: []
}

export const lists = [
  {
    unfold: false,
    collect: false,
    id: 1,
    title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
    source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
    impact_factor: 1.0,
    journal_abbreviation: 'J Clin Oncol',
    journal: 'J Clin Oncol...',
    abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
    publish_year: 2020,
    is_new: true,
    article_data_type: 'article',
    other_info: {
      pmid: '29884413',
      authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
      keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
    }
  },
  {
    unfold: false,
    id: 2,
    collect: false,
    title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
    source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
    impact_factor: 1.0,
    journal_abbreviation: 'J Clin Oncol',
    journal: 'J Clin Oncol...',
    abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
    publish_year: 2020,
    is_new: true,
    article_data_type: 'article',
    other_info: {
      pmid: '29884413',
      authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
      keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
    }
  },
  {
    unfold: false,
    id: 3,
    collect: false,
    title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
    source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
    impact_factor: 1.0,
    journal_abbreviation: 'J Clin Oncol',
    journal: 'J Clin Oncol...',
    abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
    publish_year: 2020,
    is_new: true,
    article_data_type: 'article',
    other_info: {
      pmid: '29884413',
      authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
      keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
    }
  }
  // {
  //   unfold: false,
  //   id: 4,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 5,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 6,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 7,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 8,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 9,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // },
  // {
  //   unfold: false,
  //   id: 10,
  //   title: 'PIVKA-II as a Potential New Biomarker for Hepatocellular Carcinoma - A Pilot Study',
  //   source_url: 'https://pubmed.ncbi.nlm.nih.gov/30348715/',
  //   impact_factor: 1.0,
  //   journal_abbreviation: 'J Clin Oncol',
  //   journal: 'J Clin Oncol...',
  //   abstract: 'Aim: The aim of this study was to evaluate the clinical contribution of protein induced by vitamin K absence (PIVKA-II) for hepatocellular carcinoma (HCC) diagnostics and compare it with alpha-foetoprotein (AFP), a routinely used tumour marker. Materials and methods: A total of 332 participants were enrolled in this study: 64 with HCC, 48 with liver metastases of colorectal cancer origin, 42 with liver cirrhosis and 178 healthy individuals. Serum levels of PIVKA-II were measured using the chemiluminescent assay of the Architect 1000i System (Abbott, USA) and AFP levels using the chemiluminescent assay by DxI 800 (Beckman Coulter, USA). Results: PIVKA-II achieved better clinical sensitivity than AFP and the difference in this sensitivity was statistically significant. PIVKA-II achieved the best sensitivity (96.9%) in distinguishing between the HCC and control groups with the proposed cut-off value of 60 mAU/ml. Conclusion: Our recommendation is for addition of PIVKA-II to the routine panel of HCC tumour markers.',
  //   publish_year: 2020,
  //   is_new: true,
  //   article_data_type: 'article',
  //   other_info: {
  //     pmid: '29884413',
  //     authors: 'Masakazu Toi , Mario Campone , Joohyuk Sohn , Shani Paluch-Shimon ，Jens Huober , In Hae Park , Olivier Trédan , Shin-Cheh Chen ,Masakazu Toi , Mario',
  //     keywords: 'AFP; PIVKA-II; biomarkers; hepatocellular carcinoma; predictive'
  //   }
  // }
]