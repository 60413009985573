<template>
  <div v-if="option" class="guide-item-wrap">
    <div class="left">
      <Checkbox :label="option.id">
        <span></span>
      </Checkbox>
      <span @click="handleTitleClick" class="title" v-html="option.title"></span>
      <div class="is-new" v-if="option.is_new">
        <img src="../../../../assets/imgs/new.png" alt="/">
      </div>
    </div>
    <div class="feed-item">
      <div class="label">发布机构：</div>
      <div class="value">{{ option.institute }}</div>
    </div>
    <div class="feed-item">
      <div class="label">发表年份：</div>
      <div class="value">{{ option.publish_year }}</div>
    </div>
    <div class="bottom-item">
      <div class="feed-item">
        <div class="label">原文下载：</div>
        <div class="url" @click="handleSource">下载链接</div>
      </div>
      <div class="feed-item" style="margin-left: 20px;">
        <div class="label">内容解读：</div>
        <div class="value">
          <div
            v-for="(c, index) in option.other_info.interpreter"
            :key="index"
            :class="{'ddzj': c.name === '大道至检e课堂', 'ymt': c.name === '医脉通', 'yw': c.name === '原文解读'}"
            @click="handleContent(c)"
          >{{ c.name }}</div>
        </div>
      </div>
    </div>
    <div class="impact-factor-wrap">
      <div class="right" v-if="option.journal || option.impact_factor">
        <span class="journal" v-if="option.journal">{{option.journal.length > 64 ? `${option.journal.slice(0, 32)}...` : option.journal}} <span style="color: #007FFF">{{option.journal_type_info}}</span></span>
        <span class="factor" v-if="option.impact_factor">impact factor:{{option.impact_factor.toFixed(2)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Message } from 'view-design'
export default {
  props: {
    option: {
      type: Object,
      default: () => null
    }
  },
  components: {
    Checkbox
  },
  watch: {
    option: {
      handler(val) {
        console.log(val, 'props')
      },
      immediate: true
    }
  },
  methods: {
    handleTitleClick() {
      if (this.option.source_url) {
        window.open(this.option.source_url)
      }
    },
    handleSource() {
      if (this.option.source_url) {
        window.open(this.option.source_url)
      } else {
        Message.warning('暂无下载链接')
      }
    },
    handleContent(item) {
      if (item.url) {
        window.open(item.url)
      } else {
        Message.warning('暂无内容解读链接')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>