<template>
  <div class="dashboard-card-wrap">
    <div class="dashboard-card-header">
      <div class="info">
        共<span>{{count}}</span>条数据
      </div>
      <div class="dashboard-new">
        <Button type="primary" ghost @click="handleDashboard">文献列表</Button>
        <Button class="new" type="primary" @click="handleNew">NEW {{newCount || ''}}</Button>
      </div>
    </div>
    <div class="dashboard-content-wrap">
      <ChardCard
        label="发表趋势"
        cardId="publish"
        :showCount="true"
        :count="publish.count"
        :export="exportPublishTrend"
        :loading="publish.loading"
      >
        <Histogram
          :size="20"
          chartID="publishTrend"
          :chartData="publish.data"
          @histogram="navigatorPublishHistogram"
        />
      </ChardCard>
       <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="作者排行"
            cardId="author"
            :count="author.count"
            :showCount="false"
            :isTable="true"
            :export="exportAuthorRank"
            :loading="author.loading"
          >
            <!-- <template #right>
              <Toggle
                :current="authorCurrent"
                @menu="isAuthorToggle"
              />
            </template> -->
            <Table
              :columns="authorColumns"
              :data="author.data"
              @rowClick="navigatorAuthorRank"
            />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="机构排行"
            cardId="institution"
            :count="institution.count"
            :showCount="false"
            :isTable="true"
            :export="exportInstitution"
            :loading="institution.loading"
          >
            <!-- <template #right>
              <Toggle
                :current="institutionCurrent"
                @menu="isInstitutionToggle"
              />
            </template> -->
            <Table
              :columns="institutionColumns"
              :data="institution.data"
              @rowClick="navigatorInstitutionRank"
            />
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="影响因子分布"
            cardId="factore"
            :count="impact.count"
            :export="exportImpactFactor"
            :loading="impact.loading"
          >
            <RadialBar
              chartID="impactFactor"
              :chartData="impact.data"
              @radial='navigatorRadial'
            />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="相关疾病"
            cardId="disease"
            :count="disease.count"
            :export="exportDisease"
            :loading="disease.loading"
          >
            <Pie
              :count="disease.count"
              subTitle="相关疾病"
              chartID="relateDisease"
              :innerRadius="0.8"
              :chartData="disease.data"
              @pie="navigatorRelativeDisease"
            />
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="临床应用"
            cardId="clinical"
            :count="clinical.count"
            :export="exportClinical"
            :loading="clinical.loading"
          >
            <Pie
              :count="clinical.count"
              subTitle="临床应用"
              chartID="clinicalApply"
              :innerRadius="0.8"
              :chartData="clinical.data"
              @pie="navigatorClinical"
            />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="研究人群"
            cardId="study"
            :count="crowd.count"
            :export="exportResearch"
            :loading="crowd.loading"
          >
            <Pie
              :count="crowd.count"
              subTitle="研究人群"
              chartID="studyCrowd"
              :innerRadius="0.8"
              :chartData="crowd.data"
              @pie="navigatorCrowd"
            />
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="文献类型"
            cardId="literature"
            :showCount="false"
            :count="literature.count"
            :export="exportLiterature"
            :loading="literature.loading"
          >
            <Pie
              :count="literature.count"
              subTitle="文献类型"
              chartID="literatureType"
              :innerRadius="0.8"
              :chartData="literature.data"
              @pie="navigatorLiterature"
            />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="中外文献"
            cardId="notion"
            :showCount="false"
            :count="docLiterature.count"
            :export="exportDocument"
            :loading="docLiterature.loading"
          >
            <Histogram
              :size="16"
              chartID="notionLiterature"
              :chartData="docLiterature.data"
              @histogram="navigatorLanguage"
            />
          </ChardCard>
        </Col>
      </Row>
      <ChardCard
        label="高频关键词"
        cardId="keywords"
        :count="words.count"
        :export="exportFrequencyWords"
        :loading="words.loading"
      >
        <WordCloud
          chartID="frequencyKeywords"
          :chartData="words.data"
          @cloud="navigatorFrequencyWords"
        />
      </ChardCard>
    </div>
  </div>
</template>

<script>
import { Histogram, Pie, WordCloud, RadialBar } from 'components/Charts'
import ChardCard from 'components/ChardCard'
import Table from 'components/Table'
// import Toggle from 'components/Toggle'
import { Button, Row, Col } from 'view-design'
import { authorColumns, institutionColumns } from './config'
import isEqual from 'lodash/isEqual'
import { dashboard } from '@/mixins/dashboard'
import { mapMutations } from 'vuex'

import { debounce } from '@/utils/tools'

export default {
  name: 'DashBoard',
  inject: ['listsParent'],
  mixins: [dashboard],
  data () {
    return {
      publish: {
        data: [],
        count: 0,
        loading: true
      },
      disease: {
        data: [],
        count: 0,
        loading: true
      },
      impact: {
        data: [],
        count: 0,
        loading: true
      },
      clinical: {
        data: [],
        count: 0,
        loading: true
      },
      authorColumns,
      institutionColumns,
      author: {
        data: [],
        count: 0,
        loading: true
      },
      institution: {
        data: [],
        count: 0,
        loading: true
      },
      crowd: {
        data: [],
        count: 0,
        loading: true
      },
      docLiterature: {
        data: [],
        count: 0,
        loading: true
      },
      literature: {
        data: [],
        count: 0,
        loading: true
      },
      words: {
        data: [],
        count: 0,
        loading: true
      },
      // authorCurrent: 0,
      // institutionCurrent: 0,
      filterOption: {}
    }
  },

  props: {
    count: {
      type: Number,
      default: 0
    },
    newCount: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default: () => {}
    },
    recent: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Button,
    ChardCard,
    Histogram,
    Pie,
    Row,
    Col,
    Table,
    // Toggle,
    WordCloud,
    RadialBar
  },

  watch: {
    filters: {
      handler(val, oldval) {
        const vm = this
        this.filterOption = val
        if (!isEqual(val, oldval)) {
          this.emitFetchFunc(vm)
        }
      },
      immediate: true,
      true: true
    }
  },

  methods: {
    ...mapMutations('literature', ['setFlag']),

    // watch监听防抖函数
    emitFetchFunc: debounce((vm) => {
      vm.publishTrend()
      vm.authorsRank()
      vm.institutionRank()
      vm.relativeDisease()
      vm.impactFactorDistribution()
      vm.clinicalUse()
      vm.researchCrowd()
      vm.literatureType()
      vm.documentLiterature()
      vm.frequencyWordsWays()
    }, 1000),

    handleDashboard() {
      this.$emit('dashboard')
    },
    handleNew() {
      this.$emit('new')
    },
    // isAuthorToggle(item) {
    //   const { key } = item
    //   this.authorCurrent = key
    // },
    // isInstitutionToggle(item) {
    //   const { key } = item
    //   this.institutionCurrent = key
    // },
    // 发表趋势跳转
    setCommonNavigator(option, name, key) {
      const combine = {
        ...option,
        field: this.listsParent.formulas[0],
        variety: this.listsParent.formulas[1],
        chartName: key === 'impact_factor__range' ? `${name}=${option.chart}` : `${name}=${option[key]}`,
        formulas: this.listsParent.formulatesComputerd
      }
      this.setFlag(false)
      this.$router.push({
        path: 'detail',
        query: {
          combine: JSON.stringify(combine)
        }
      })
    },
    navigatorPublishHistogram({ name }) {
      const option = {
        ...this.filterOption,
        publish_year__in: name
      }
      this.setCommonNavigator(option, '发表趋势', 'publish_year__in')
    },
    // 作者排行跳转
    navigatorAuthorRank({ label }) {
      const option = {
        ...this.filterOption,
        article__authors__contains: `"${label}"`
      }
      this.setCommonNavigator(option, '作者排行', 'article__authors__contains')
    },
    // 机构排行跳转
    navigatorInstitutionRank({ label }) {
      const option = {
        ...this.filterOption,
        institute__contains: label
      }
      this.setCommonNavigator(option, '机构排行', 'institute__contains')
    },
    // 影响因子
    navigatorRadial({ filter, name }) {
      const option = {
        ...this.filterOption,
        chart: name,
        impact_factor__range: filter
      }
      this.setCommonNavigator(option, '影响因子分布', 'impact_factor__range')
    },
    // 相关疾病
    navigatorRelativeDisease({ name }) {
      const option = {
        ...this.filterOption,
        disease__name: name
      }
      this.setCommonNavigator(option, '相关疾病', 'disease__name')
    },
    // 临床应用
    navigatorClinical({ name }) {
      const option = {
        ...this.filterOption,
        clinical_application__name: name
      }
      this.setCommonNavigator(option, '临床应用', 'clinical_application__name')
    },
    // 研究人群
    navigatorCrowd({ name }) {
      const option = {
        ...this.filterOption,
        population__name: name
      }
      this.setCommonNavigator(option, '研究人群', 'population__name')
    },
    // 文献类型
    navigatorLiterature({ name }) {
      const option = {
        ...this.filterOption,
        article_type__name: name
      }
      this.setCommonNavigator(option, '文献类型', 'article_type__name')
    },
    // 中英文献
    navigatorLanguage({ name }) {
      const option = {
        ...this.filterOption,
        language__in: name
      }
      this.setCommonNavigator(option, '中外文献', 'language__in')
    },
    // 高频关键词
    navigatorFrequencyWords({ name }) {
      const option = {
        ...this.filterOption,
        bio_markers: name
      }
      this.setCommonNavigator(option, '高频关键词', 'bio_markers')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>