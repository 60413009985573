<template>
  <div class="literature-lists-wrap">
    <HeaderInfo
      :formulas="formulatesComputerd"
      :diff="isDiffHeader"
      :disease="disease"
      :field="field"
      :init="formulas.length"
      @back="isGoBack"
      @clear="isClear"
      @subscribe="isSubscribe"
    />
    <!-- 不同的入口 -->
    <div class="diff-header" v-if="isDiffHeader">
      <!-- search -->
      <template v-if="isDiffHeader === 'normal'">
        <div class="lists-search-area">
          <SearchCard :carry="search" @search="isSearch" />
        </div>
      </template>
      <template v-if="isDiffHeader === 'advanced'">
        <div class="lists-advanced-condition">
          <div class="input-area">
            <AdvancedInput
              v-for="(item, index) in advanced"
              :key="item + index"
              :advancedKey="index"
              :advanced="item"
              :len="advanced.length - 1"
              size="large"
              @minus="isMinus"
              @plus="isPlus"
            />
          </div>
          <Button
            size="large"
            type="primary"
            class="lists-advanced-search"
            @click="isAdvanceSearch"
            >搜索</Button
          >
        </div>
      </template>
    </div>

    <div class="new-search-wrap" v-if="!isDiffHeader">
      <div class="search-input-wrap" v-if="searchValue.type === 'search'">
        <Input
          v-model="searchValue.search"
          placeholder="输入关键词查询"
          size="large"
        />
        <div class="btn">
          <Button type="primary" size="large" @click="handelSearch"
            >搜索</Button
          >
          <Button
            style="margin-left: 16px;"
            size="large"
            @click="handelChangeType('advanced')"
            >高级搜索</Button
          >
        </div>
      </div>
      <div class="advance-input-wrap" v-if="searchValue.type === 'advanced'">
        <div class="input-wrap">
          <AdvancedInput
            v-for="(item, index) in searchValue.advanced"
            :key="item + index"
            :advancedKey="index"
            :advanced="item"
            :len="searchValue.advanced.length - 1"
            size="large"
            @minus="handleAdvanceMinus"
            @plus="handleAdvancePlus"
          />
        </div>
        <Button size="large" type="primary" @click="handleAdvanceSearch"
          >搜索</Button
        >
        <Button
          style="margin-left: 16px;"
          size="large"
          @click="handelChangeType('search')"
          >返回</Button
        >
      </div>
    </div>

    <div class="lists-card-wrap">
      <Row :gutter="24">
        <Col span="6">
          <div class="lists-left">
            <div class="skeleton-wrap">
              <skeleton
                v-if="treeLoading"
                type="listcom"
                active
                :options="skeletonOption"
              />
            </div>
            <LabelsCard v-if="isBioMarkerShow && !isDiffHeader">
              <LabelsItem
                v-if="filterOption.bio_marker.children.length"
                :option="filterOption.bio_marker"
                @label="isLabelClick"
                @fold="isLabelsFold"
              />
            </LabelsCard>
            <LabelsCard v-if="!isDiffHeader && isSecondCardShow">
              <LabelsItem
                v-if="filterOption.disease.children.length"
                :option="filterOption.disease"
                @label="isLabelClick"
              />
              <LabelsItem
                v-if="filterOption.clinical_application.children.length"
                :option="filterOption.clinical_application"
                @label="isLabelClick"
              />
              <LabelsItem
                v-if="filterOption.population.children.length"
                :option="filterOption.population"
                @label="isLabelClick"
              />
            </LabelsCard>
            <LabelsCard>
              <LabelsItem
                v-if="filterOption.language.children.length"
                :option="filterOption.language"
                @label="isLabelClick"
              />
              <LabelsItem
                v-if="filterOption.article_type.children.length"
                :option="filterOption.article_type"
                @label="isLabelClick"
              />
              <!-- <LabelsItem
                v-if="filterOption.publish_year.children.length"
                :option="filterOption.publish_year"
                @label="isLabelClick"
              />
              <LabelsItem
                v-if="filterOption.impact_factor.children.length"
                :option="filterOption.impact_factor"
                @label="isLabelClick"
              /> -->
              <DateItem
                :option="sliderOption.publish_year"
                @start="isStartClick"
                @end="isEndClick"
                v-if="sliderOption.publish_year.range.length"
              />
              <!-- <SliderItem
                :option="sliderOption.publish_year"
                @slider="isSliderOfYear"
              /> -->
              <SliderItem
                :option="sliderOption.impact_factor"
                @slider="isSliderOfImpact"
                v-if="sliderOption.impact_factor.range.length"
              />
            </LabelsCard>
          </div>
        </Col>
        <Col span="18">
          <div class="lists-right">
            <template v-if="!isDashborad">
              <template v-if="lists.length">
                <ListCard
                  :count="total"
                  :checkAll="checkAll"
                  :unsure="unsure"
                  :timeLighten="sortTime"
                  :timeAsc="timeAsc"
                  :timeDesc="timeDesc"
                  :ifAsc="ifAsc"
                  :ifDesc="ifDesc"
                  :relateLighten="sortRelate"
                  :iFLighten="sortIF"
                  :isSelect="checkAllGroup.length"
                  :newCount="newCount"
                  :fullTextVisible="fullTextVisible"
                  @collect="isCollect"
                  @download="isDownload"
                  @time="isTimeSort"
                  @impact="isImpactSort"
                  @relate="isRelateSort"
                  @all="isCheckAll"
                  @dashboard="isDashBoard"
                  @new="isNew"
                  @fullText="isFullText"
                >
                  <CheckboxGroup
                    v-model="checkAllGroup"
                    @on-change="checkAllGroupChange"
                  >
                    <div v-for="(item, index) in lists" :key="index">
                      <ListItem
                        v-if="item.article_data_type === 'article'"
                        :option="item"
                        :itemKey="index"
                        @fold="isFold"
                        @collect="isCardCollect"
                        @more="toggleMore"
                      />
                      <GuideItem v-else :option="item" :itemKey="index" />
                    </div>
                  </CheckboxGroup>
                  <div class="literature-lists-footer">
                    <Page
                      :current="page"
                      :total="total"
                      show-elevator
                      @on-change="isPageChange"
                    />
                  </div>
                </ListCard>
              </template>
              <template v-else>
                <div class="lists-no-data">
                  <img src="../../../assets/imgs/empty.png" />
                </div>
              </template>
            </template>
            <template v-else>
              <DashBoard
                :count="total"
                :newCount="newCount"
                :filters="getCommonFilterParams"
                :recent="recent"
                @dashboard="isDashBoard"
                @new="isNew"
              />
            </template>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import {
  fetchFiltersItems,
  fetchArticles,
  fetchFormulasSubscribe
} from '@/api'
import HeaderInfo from '../components/HeaderInfo'
import LabelsCard from '../components/LabelsCard'
import LabelsItem from '../components/LabelsItem'
import SliderItem from '../components/SliderItem'
import DateItem from '../components/DateItem'
import ListCard from '../components/ListCard'
import ListItem from '../components/ListItem'
import SearchCard from '../components/SearchCard'
import AdvancedInput from '../components/AdvancedInput'
import DashBoard from '../components/Dashboard'
import GuideItem from '../components/GuideItem/index.vue'
import { biomarker, relateDisease, clinical, crowd, language, literature, publish, impact } from './config'
import { CheckboxGroup, Page, Button, Row, Col, Message, Input } from 'view-design'
import { listMixins } from '@/mixins/listMixins'
import {
  setLabelsOption,
  setFilterFromResponse,
  setStringify,
  setAdvancedStr,
  setSubscribeFetch,
  subscribeContent,
  savePdfWithOpen,
  setSliderFromRes
} from 'utils/tools'
import cloneDeep from 'lodash/cloneDeep'
import { mapState } from 'vuex'
export default {
  name: 'Lists',

  provide() {
    return {
      listsParent: this
    }
  },

  mixins: [listMixins],

  data () {
    return {
      isDashborad: false,
      formulas: [],
      filterOption: {
        bio_marker: cloneDeep(biomarker),
        disease: cloneDeep(relateDisease),
        clinical_application: cloneDeep(clinical),
        population: cloneDeep(crowd),
        language: cloneDeep(language),
        article_type: cloneDeep(literature)
      },
      sliderOption: {
        publish_year: cloneDeep(publish),
        impact_factor: cloneDeep(impact)
      },
      fullText: {},
      fullTextVisible: false,
      unsure: false,
      checkAll: false,
      checkAllGroup: [],
      field: '',
      disease: '',
      lists: [],
      total: 0,
      page: 1,
      sortRelate: true,
      sortTime: false,
      timeAsc: false,
      timeDesc: false,
      sortIF: false,
      ifAsc: false,
      ifDesc: false,
      recently: false,
      search: '',
      advanced: [], // 高级检索
      advancedExpression: '',
      collect: [], // 收藏的检索式
      isDiffHeader: '', // 不同进入 normal advanced collect
      savedLabels: [],
      saveFormulas: [],
      collectOption: {},
      newCount: '',
      treeLoading: true,
      skeletonOption: {
        row: 28,
        lineHight: 10
      },
      queryBackups: {},
      isSameYear: true,
      yearRange: '',
      impactRange: '',
      searchValue: {
        type: 'search',
        search: '',
        advanced: [
          { label: 'Title', search: '' }
        ]
      }
    }
  },

  computed: {
    ...mapState('literature', ['listFlag']),
    getUserSelectLabel() {
      return setLabelsOption(this.filterOption)
    },
    // 文献列表公共的请求参数
    formulatesComputerd() {
      return Array.from(new Set([...this.formulas, ...this.saveFormulas]))
    },
    sliderInitialImpactor() {
      return (this.sliderOption.impact_factor.range[0] === this.sliderOption.impact_factor.valueRange[0]) && (this.sliderOption.impact_factor.range[1] === this.sliderOption.impact_factor.valueRange[1])
    },
    // dateInitialYear() {
    //   return (this.sliderOption.publish_year.range[0] === Number(this.sliderOption.publish_year.valueRange[0])) && (this.sliderOption.publish_year.range[1] === Number(this.sliderOption.publish_year.valueRange[1]))
    // }
    sliderInitialYear() {
      return (this.sliderOption.publish_year.range[0] === this.sliderOption.publish_year.valueRange[0]) && (this.sliderOption.publish_year.range[1] === this.sliderOption.publish_year.valueRange[1])
    },
    // isSameYear({ sliderOption }) {
    //   const { publish_year: { range, valueRange } } = sliderOption
    //   return range[0] === Number(valueRange[0]) && range[1] === Number(valueRange[1])
    // },
    getCommonFilterParams() {
      // publish_year impact_factor
      const { temp: { bio_marker, clinical_application, population, article_type, language, disease }, save, formulas } = setStringify(this.getUserSelectLabel)
      this.savedLabels = save.length ? save : []
      this.saveFormulas = formulas.length ? formulas : []
      const option = {
        page: this.page,
        // disease__id: this.disease || undefined,
        field__id: this.field || this.disease || undefined,
        bio_markers: this.search || this.searchValue.search || undefined,
        bio_expression: (this.advanced.length || this.searchValue.advanced.length) ? this.advancedExpression : undefined,
        bio_markers__bio_marker_id__in: bio_marker,
        disease__id__in: disease,
        clinical_application__id__in: clinical_application,
        population__id__in: population,
        language__in: language,
        article_type__id__in: article_type,
        // publish_year,
        // impact_factor,
        publish_year_asc: this.sortTime
          ? this.timeDesc
            ? '0' : '1'
          : undefined,
        impact_factor_asc: this.sortIF
          ? this.ifDesc
            ? '0' : '1'
          : undefined,
        relevance_asc: this.sortRelate ? '0' : undefined,
        is_new: this.recently ? '1' : undefined,
        impact_factor__range: this.sliderInitialImpactor ? undefined : this.sliderOption.impact_factor.valueRange.join(','),
        // this.isSameYear ? undefined :
        publish_year__range: this.isSameYear ? undefined : this.yearRange,
        ...this.collectOption,
        ...this.fullText
      }
      return option
    }
  },

  components: {
    HeaderInfo,
    LabelsCard,
    LabelsItem,
    SliderItem,
    DateItem,
    ListCard,
    ListItem,
    CheckboxGroup,
    Page,
    SearchCard,
    AdvancedInput,
    Button,
    DashBoard,
    Row,
    Col,
    Input,
    GuideItem
  },

  // created() {
  //   this.getDefaultFormula()
  // },

  activated() {
    if (this.listFlag) {
      this.collectOption = {}
      this.search = ''
      this.formulas = []
      this.setCommonInitial()
      this.isDashborad = false
      this.getDefaultFormula()
      this.fullTextVisible = false
      this.fullText = {}
      this.searchValue.search = ''
    }
  },

  methods: {
    handelSearch() {
      this.Articles(this.getCommonFilterParams, true, true)
    },
    handleAdvanceSearch() {
      const len = this.searchValue.advanced.length
      const lastItem = this.searchValue.advanced[len - 1]
      if (len > 1 && (!lastItem.label || !lastItem.logic || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else if (len === 1 && (!lastItem.label || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else {
        this.advancedExpression = setAdvancedStr(this.searchValue.advanced)
        this.Articles(this.getCommonFilterParams, true, true)
      }
    },
    handelChangeType(type) {
      this.searchValue.type = type
    },
    handleAdvancePlus() {
      const item = {
        label: '',
        logic: '',
        search: ''
      }
      this.searchValue.advanced.push(item)
    },
    handleAdvanceMinus(key) {
      this.searchValue.advanced.splice(key, 1)
    },
    // 右侧列表
    async Articles(option = {}, setLabel = false, clear = false, type) {
      this.unsure = false
      this.checkAll = false
      this.checkAllGroup = []
      console.log('articles', option)
      const res = await fetchArticles(option)
      const { data: { data, new_count }, page: { count } } = res.data
      if (!data.length && type === 'new') {
        Message.warning({
          content: '暂无七日更新数据！',
          duration: 2
        })
        this.recently = false
        return
      }
      if (!data.length) {
        this.lists = []
        return
      }
      this.newCount = new_count
      this.total = count
      this.lists = data.map(item => ({
        unfold: false,
        collect: false,
        isMore: false,
        ...item
      }))
      this.treeLoading = true
      fetchFiltersItems(option).then(tags => {

        clear && setSliderFromRes(tags.data, this.sliderOption)
        clear && setFilterFromResponse(tags.data, this.filterOption)
        !setLabel && setFilterFromResponse(tags.data, this.filterOption)
        if (this.savedLabels.length && !setLabel) {
          Object.keys(this.filterOption).forEach(item => {
            this.savedLabels.forEach(label => {
              this.filterOption[item].children.forEach((child, key) => {
                if (child.label === label) {
                  this.filterOption[item].children[key].isSelect = true // tags的id重复
                }
              })
            })
          })
        }
        this.treeLoading = false
      })
    },
    // 公共请求方法
    setCommonFetch(option) {
      this.Articles(option, true, true)
    },
    // 区分不同方式跳转 -> UI 不同
    getDefaultFormula() {
      const { query } = this.$route
      this.queryBackups = cloneDeep(query)
      // 从卡片
      const type = query.type
      if (!type) {
        let target
        if (query.disease__id) {
          this.disease = target = query.disease__id
          delete query.disease__id
        } else {
          this.field = target = query.field__id
          delete query.field__id
        }
        const option = {
          page: this.page,
          field__id: target,
          relevance_asc: '0'
        }
        this.setCommonFetch(option)
        Object.keys(query).forEach(item => this.formulas.push(query[item]))
      } else { // 检索进入
        const { type } = query
        this.isDiffHeader = type
        this.isDiffSwitch(type, query)
      }
    },
    isDiffSwitch(type, query) {
      switch (type) {
        // search
        case 'normal':
          this.search = query.search
          const option = {
            bio_markers: this.search
          }
          this.setCommonFetch(option)
          break
        // 收藏的检索式
        case 'collect':
          const { collect, search_type, search_content } = query
          this.formulas = collect.split(',').filter(item => item.toLowerCase() !== 'and')
          if (search_type === 'search') {
            this.search = search_content
            this.collectOption = cloneDeep(this.getCommonFilterParams)
            this.Articles(this.getCommonFilterParams, true, true)
          } else if (search_type === 'advanced_search') {
            const option = {
              ...this.getCommonFilterParams,
              bio_expression: collect
            }
            this.collectOption = cloneDeep(option)
            this.Articles(this.collectOption, undefined, true)
          } else {
            this.collectOption = {
              ...JSON.parse(search_content)
            }
            this.Articles(this.collectOption, undefined, true)
          }
          break
        // 高级检索
        case 'advanced':
          this.advanced = JSON.parse(query.advanced)
          console.log(this.advanced, 'advanced')
          this.advancedExpression = setAdvancedStr(this.advanced)
          this.Articles(this.getCommonFilterParams, undefined, true)
          break
        default:
          break
      }
    },
    // 滑块
    // isSliderOfYear(val) {
    //   this.sliderOption.publish_year.valueRange = val
    //   const option = {
    //     ...this.getCommonFilterParams,
    //     publish_year__range: val.join(',')
    //   }
    //   this.Articles(option)
    // },
    isSliderOfImpact(val) {
      this.sliderOption.impact_factor.valueRange = val
      this.Articles(this.getCommonFilterParams)
    },
    dateInitialYear() {
      this.isSameYear = !!(this.sliderOption.publish_year.range[0] === Number(this.sliderOption.publish_year.valueRange[0]) && this.sliderOption.publish_year.range[1] === Number(this.sliderOption.publish_year.valueRange[1]))
      this.yearRange = this.sliderOption.publish_year.valueRange.join(',')
      this.Articles(this.getCommonFilterParams)
    },
    // 年份点击
    isStartClick(val) {
      this.sliderOption.publish_year.valueRange[0] = val
      this.dateInitialYear()
    },
    isEndClick(val) {
      this.sliderOption.publish_year.valueRange[1] = val
      this.dateInitialYear()
    },
    // 普通检索
    isSearch(val) {
      this.search = val
      this.Articles(this.getCommonFilterParams, true, true)
    },
    isPlus() {
      const item = {
        label: '',
        logic: '',
        search: ''
      }
      this.advanced.push(item)
    },
    isMinus(key) {
      this.advanced.splice(key, 1)
    },
    isGoBack() {
      this.$router.go(-1)
    },
    setCommonInitial() {
      this.search = ''
      this.savedLabels = []
      this.saveFormulas = []
      this.checkAllGroup = []
      this.sortTime = this.timeAsc = this.timeAsc = false
      this.sortIF = this.ifAsc = this.ifDesc = false
      this.page = 1
      this.isDiffHeader = this.disease = this.field = ''
      this.checkAll = false
      this.unsure = false
      this.sortRelate = true
      this.advancedExpression = ''
      this.advanced = []
    },
    // 清空筛选
    isClear() {
      this.setCommonInitial()
      this.disease = this.queryBackups.disease__id
      this.field = this.queryBackups.field__id
      const option = {
        field__id: this.field || this.disease || undefined,
        relevance_asc: '0'
      }
      this.fullTextVisible = false
      this.fullText = {}
      this.Articles(option, true, true)
    },
    async setSubscribe(option) {
      const res = await fetchFormulasSubscribe(option)
      const { code, message } = res
      if (code === 200) {
        Message.success({
          content: '订阅成功！',
          duration: 2
        })
      } else {
        Message.warning({
          content: message,
          duration: 2
        })
      }
    },
    isSubscribe() {
      const option = {
        is_subscribed: '1'
      }
      switch (this.isDiffHeader) {
        // search
        case 'normal':
          option.bio_markers = this.search
          this.setSubscribe(option)
          break
        // 高级检索
        case 'advanced':
          option.bio_expression = this.advancedExpression
          this.setSubscribe(option)
          break
        default:
          const { bio_marker, clinical_application, population, disease } = setSubscribeFetch(this.getUserSelectLabel)
          const temp = {
            bio_markers__bio_marker_id__in: bio_marker || undefined,
            clinical_application__id__in: clinical_application || undefined,
            population__id__in: population || undefined,
            disease__id__in: disease || undefined,
            content: subscribeContent(this.formulatesComputerd, this.disease),
            field__id__in: this.field || this.disease || undefined,
            ...option
          }
          this.setSubscribe(temp)
          break
      }
    },
    // 展开
    isLabelsFold(val) {
      this.filterOption[val].unfold = !this.filterOption[val].unfold
    },
    isLabelClick(temp) {
      this.page = 1
      const { key, value } = temp
      this.filterOption[value].children[key].isSelect = !this.filterOption[value].children[key].isSelect
      this.Articles(this.getCommonFilterParams)
    },
    exportArticles() {
      const temp = {
        id__in: this.checkAllGroup.join(',')
      }
      savePdfWithOpen(this.getCommonFilterParams, 'articles/export', temp)
    },
    isDownload() {
      if (this.checkAllGroup.length) {
        this.exportArticles()
        this.checkAllGroup = []
        this.checkAll = false
        this.unsure = false
      } else {
        Message.warning({
          content: '请选择要导出的数据！',
          duration: 2
        })
      }
    },
    // 收藏页
    isCollect() {
      if (this.checkAllGroup.length) {
        console.log(this.checkAllGroup)
      } else {
        Message.warning({
          content: '请选择要收藏的数据！',
          duration: 2
        })
      }
    },
    isAdvanceSearch() {
      const len = this.advanced.length
      const lastItem = this.advanced[len - 1]
      if (len > 1 && (!lastItem.label || !lastItem.logic || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else if (len === 1 && (!lastItem.label || !lastItem.search)) {
        Message.warning({
          content: '请完善搜索条件',
          duration: 2
        })
      } else {
        this.advancedExpression = setAdvancedStr(this.advanced)
        this.Articles(this.getCommonFilterParams, true, true)
      }
    },

    isTimeSort() {
      this.sortTime = true
      if (!this.timeDesc) {
        this.timeAsc = false
        this.timeDesc = true
      } else {
        this.timeAsc = true
        this.timeDesc = false
      }
      this.sortIF = this.sortRelate = this.ifDesc = this.ifAsc = false
      this.Articles(this.getCommonFilterParams, true)
    },
    isImpactSort() {
      this.sortIF = true
      if (!this.ifDesc) {
        this.ifAsc = false
        this.ifDesc = true
      } else {
        this.ifAsc = true
        this.ifDesc = false
      }
      this.sortTime = this.sortRelate = this.timeDesc = this.timeAsc = false
      this.Articles(this.getCommonFilterParams, true)
    },
    isRelateSort() {
      if (!this.sortRelate) {
        this.sortRelate = true
        this.sortIF = this.sortTime = this.timeDesc = this.timeAsc = this.ifDesc = this.ifAsc = false
        this.Articles(this.getCommonFilterParams, true)
      }
    },
    isCheckAll() {
      if (this.unsure) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.unsure = false
      if (this.checkAll) {
        const temp = []
        this.lists.forEach(item => temp.push(item.id))
        this.checkAllGroup = temp
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.lists.length) {
        this.unsure = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.unsure = true
        this.checkAll = false
      } else {
        this.unsure = false
        this.checkAll = false
      }
    },
    isFold(key) {
      this.lists[key].unfold = !this.lists[key].unfold
    },
    isCardCollect(key) {
      this.lists[key].collect = !this.lists[key].collect
    },
    toggleMore(key) {
      this.lists[key].isMore = !this.lists[key].isMore
    },
    isPageChange(page) {
      this.checkAllGroup = []
      this.checkAll = false
      this.unsure = false
      this.page = page
      this.Articles(this.getCommonFilterParams, true)
      const el = document.getElementById('layout')
      el.scroll(0, 20)
    },
    isDashBoard() {
      this.unsure = false
      this.checkAll = false
      this.checkAllGroup = []
      this.recent = false
      this.isDashborad = !this.isDashborad
      this.recently = false
    },
    isNew() {
      this.recently = !this.recently
      this.Articles(this.getCommonFilterParams, true, true, 'new')
    },
    isFullText() {
      this.fullTextVisible = !this.fullTextVisible
      this.fullText = this.fullTextVisible ? {
        full_text: true
      } : {}
      this.Articles(this.getCommonFilterParams, true, true)
    }
  }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>
