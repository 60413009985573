import {
  fetchPublishTrend,
  fetchAuthorsRank,
  fetchInstitutionRank,
  fetchRelativeDisease,
  fetchImpactFactory,
  fetchClinicalApplication,
  fetchResearchCrowd,
  fetchLiteratureType,
  fetchLangLiterature,
  fetchFrequencyWords
} from '@/api'
import {
  setTableData,
  setPieData,
  savePdfWithOpen
} from '@/utils/tools'

export const dashboard = {
  methods: {
    // 发文趋势
    async publishTrend() {
      this.publish.loading = true
      const res = await fetchPublishTrend(this.filterOption)
      const { data, all_count } = res.data
      this.publish.data = data
      this.publish.count = all_count
      this.publish.loading = false
    },
    // 发文趋势导出
    exportPublishTrend() {
      // const blob = await fetchPublishTrend({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '发文趋势')
      savePdfWithOpen(this.filterOption, 'dashboard/publish_year/export')
    },
    // 作者排行
    async authorsRank() {
      this.author.loading = true
      const res = await fetchAuthorsRank(this.filterOption)
      const { data } = res
      this.author.data = setTableData(data)
      this.author.count = data.length
      this.author.loading = false
    },
    // 作者导出
    exportAuthorRank() {
      // const blob = await fetchAuthorsRank({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '作者排行')
      savePdfWithOpen(this.filterOption, 'dashboard/author/export')
    },
    // 机构排行
    async institutionRank() {
      this.institution.loading = true
      const res = await fetchInstitutionRank(this.filterOption)
      const { data } = res
      this.institution.data = setTableData(data)
      this.institution.count = data.length
      this.institution.loading = false
    },
    // 机构排行导出
    exportInstitution() {
      // const blob = await fetchInstitutionRank({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '机构排行')
      savePdfWithOpen(this.filterOption, 'dashboard/institute/export')
    },
    // 相关疾病
    async relativeDisease() {
      this.disease.loading = true
      const res = await fetchRelativeDisease(this.filterOption)
      const { data, all_count } = res.data
      this.disease.data = setPieData(data)
      this.disease.count = all_count
      this.disease.loading = false
    },
    // 相关疾病导出
    exportDisease() {
      // const blob = await fetchRelativeDisease({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '相关疾病')
      savePdfWithOpen(this.filterOption, 'dashboard/disease/export')
    },
    // 影响因子分布
    async impactFactorDistribution() {
      this.impact.loading = true
      const res = await fetchImpactFactory(this.filterOption)
      const { data, all_count } = res.data
      this.impact.data = data.map(item => ({
        ...item,
        per: item.freq
      }))
      this.impact.count = all_count
      this.impact.loading = false
    },
    // 影响因子分布导出
    exportImpactFactor() {
      // const blob = await fetchImpactFactory({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '影响因子分布')
      savePdfWithOpen(this.filterOption, 'dashboard/impact_factor/export')
    },
    // 临床应用
    async clinicalUse() {
      this.clinical.loading = true
      const res = await fetchClinicalApplication(this.filterOption)
      const { data, all_count } = res.data
      this.clinical.data = data
      this.clinical.count = all_count
      this.clinical.loading = false
    },
    // 临床应用导出
    exportClinical() {
      // const blob = await fetchClinicalApplication({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '临床应用')
      savePdfWithOpen(this.filterOption, 'dashboard/clinical_application/export')
    },
    // 研究人群
    async researchCrowd() {
      this.crowd.loading = true
      const res = await fetchResearchCrowd(this.filterOption)
      const { data, all_count } = res.data
      this.crowd.data = data
      this.crowd.count = all_count
      this.crowd.loading = false
    },
    // 研究人群导出
    exportResearch() {
      // const blob = await fetchResearchCrowd({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '研究人群')
      savePdfWithOpen(this.filterOption, 'dashboard/population/export')
    },
    // 文献类型
    async literatureType() {
      this.literature.loading = true
      const res = await fetchLiteratureType(this.filterOption)
      const { data, all_count } = res.data
      this.literature.data = data
      this.literature.count = all_count
      this.literature.loading = false
    },
    // 文献类型导出
    exportLiterature() {
      // const blob = await fetchLiteratureType({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '文献类型')
      savePdfWithOpen(this.filterOption, 'dashboard/article_type/export')
    },
    // 中英文文献
    async documentLiterature() {
      this.docLiterature.loading = true
      const res = await fetchLangLiterature(this.filterOption)
      const { data, all_count } = res.data
      this.docLiterature.data = data
      this.docLiterature.count = all_count
      this.docLiterature.loading = false
    },
    // 中英文文献导出
    exportDocument() {
      // const blob = await fetchLangLiterature({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '中英文献')
      savePdfWithOpen(this.filterOption, 'dashboard/language/export')
    },
    // 高频关键词
    async frequencyWordsWays() {
      this.words.loading = true
      const res = await fetchFrequencyWords(this.filterOption)
      const { data } = res
      this.words.data = data
      this.words.count = data.length
      this.words.loading = false
    },
    // 高频关键词导出
    exportFrequencyWords() {
      // const blob = await fetchFrequencyWords({
      //   is_export: '1',
      //   ...this.filterOption
      // }, { responseType: 'blob' })
      // saveFilesPdf(blob, '高频关键词')
      savePdfWithOpen(this.filterOption, 'dashboard/keywords/export')
    }
  }
}